import React, { Component } from 'react'
import {
  Row,
  Col
} from 'reactstrap'
import TextEditor from '../../modules/TextEditor/texteditor'
import { confs } from '../../bootstrap/config'
import 'react-dates/initialize'
import { DayPickerSingleDateController } from 'react-dates'
import moment from 'moment'
import 'moment/locale/fi';
import 'react-dates/lib/css/_datepicker.css';
import './survey.css'
const axios = require('axios')

export default class Survey extends Component {
  constructor() {
    super()

    this.state = {
      surveyId: null,
      sentSurveyId: null,
      groupIndex: 0,
      questionIndex: 0,
      currentGroup: null,
      surveyEnded: false,
      askedSurveys: [],
      showGroupDescriptionText: true,
      groupColor: '#FFCC00',
      freetextAnswer: "",
      answerSessionId: null,
      previousAnswers: null,
      maxQuestionAnsweredIndex: 0,
      nextQuestionIndex: 0,
      date: null
    }

    this.infoBox = React.createRef()
  }

  componentDidMount() {
    if (this.props.previousAnswerSession) {
      const answerSession = this.props.previousAnswerSession

      this.setState({
        surveyId: this.props.surveyData._id,
        sentSurveyId: this.props.surveyData.sentSurveyId,
        groupIndex: answerSession.groupIndex,
        questionIndex: answerSession.questionIndex,
        currentGroup: this.props.surveyData.questionGroups[answerSession.groupIndex],
        categoryCount: Object.keys(this.props.surveyData.questionGroups).length,
        groupColor: this.props.surveyData.questionGroups[answerSession.groupIndex].groupColor || '#FFCC00',
        answerSessionId: answerSession.answerSessionId,
        maxQuestionAnsweredIndex: answerSession.questionIndex
      }, () => this.nextQuestion())
    } else {
      this.setState({
        surveyId: this.props.surveyData._id,
        sentSurveyId: this.props.surveyData.sentSurveyId,
        currentGroup: this.props.surveyData.questionGroups[this.state.groupIndex],
        categoryCount: Object.keys(this.props.surveyData.questionGroups).length,
        groupColor: this.props.surveyData.questionGroups[this.state.groupIndex].groupColor || '#FFCC00',
        answerSessionId: this.props.answerSessionId
      })
    }
  }

  nextQuestion = () => {
    const { groupIndex, questionIndex, currentGroup, nextQuestionIndex } = this.state
    let moveToQuestion = questionIndex + 1

    if (nextQuestionIndex > questionIndex) {
      moveToQuestion = nextQuestionIndex
    }

    if (currentGroup.questions[moveToQuestion]) {
      this.setState({
        questionIndex: moveToQuestion,
        nextQuestionIndex: moveToQuestion
      })
    } else {
      if (this.props.surveyData.questionGroups[groupIndex + 1]) {
        this.setState(prevState => ({
          groupIndex: groupIndex + 1,
          questionIndex: 0,
          currentGroup: this.props.surveyData.questionGroups[groupIndex + 1],
          askedSurveys: [
            ...prevState.askedSurveys,
            groupIndex
          ],
          showGroupDescriptionText: true,
          groupColor: this.props.surveyData.questionGroups[groupIndex + 1].groupColor || '#FFCC00',
          maxQuestionAnsweredIndex: 0,
          nextQuestionIndex: 0
        }))
      } else {
        if (this.props.getNextSurvey()) {
        } else {
          this.setState({
            surveyEnded: true,
            nextQuestionIndex: 0,
            maxQuestionAnsweredIndex: 0
          }, () => this.props.clearAnswerSession(this.state.sentSurveyId))
        }
      }
    }
  }

  async onAnswer(e) {
    const { currentGroup, questionIndex, groupIndex, answerSessionId, maxQuestionAnsweredIndex } = this.state
    let maxQuestionAnsweredIndexCopy = maxQuestionAnsweredIndex

    const currentQuestion = currentGroup.questions[questionIndex];
    const answerIndex = e.target.getAttribute("data-index");
    let answer = currentQuestion.isFreetextQuestion ? { text: this.state.freetextAnswer, isFreetextQuestion: true } : currentQuestion.questionOptions[answerIndex]

    if (currentQuestion.isDateQuestion) {
      answer = { text: this.state.date ? this.state.date._d : "Ei päivämäärää", isDateQuestion: true }
      this.setState({ date: null });
    }

    if ((questionIndex === 0 && maxQuestionAnsweredIndex === 0) || questionIndex > maxQuestionAnsweredIndex) {
      maxQuestionAnsweredIndexCopy = questionIndex

      this.updateAnswerSession(groupIndex, maxQuestionAnsweredIndexCopy, answerSessionId)
      this.setState({ maxQuestionAnsweredIndex: maxQuestionAnsweredIndexCopy })
    }

    const answerSession = {
      groupIndex: groupIndex,
      questionIndex: questionIndex,
      answerSessionId: answerSessionId
    }
    console.log(answerSession);
    await this.props.storeAnswerSession(answerSession)

    const answerObj = {
      surveyId: this.state.surveyId,
      sentSurveyId: this.state.sentSurveyId,
      groupId: this.state.currentGroup.id,
      questionId: currentQuestion.id,
      iframeAnswer: true,
      answer: answer,
      answerSessionId: this.props.answerSessionId
    }

    const response = await axios({
      method: 'POST',
      url: confs.url + 'surveyAnswer',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: {
        ...answerObj
      }
    })

    if (!response.data.success) {
      console.error("Something went wrong. Cannot save answer.")
    }

    if (currentQuestion.isFreetextQuestion) {
      this.setState({
        freetextAnswer: ""
      }, () => {
        this.nextQuestion()
      })
    } else {
      this.nextQuestion()
    }
  }

  updateAnswerSession = (groupIndex, maxQuestionAnsweredIndex, answerSessionId) => {
    // store an answer session stack, so it's possible for the user to continue the survey they have left unfinished
    const answerSession = {
      groupIndex: groupIndex,
      questionIndex: maxQuestionAnsweredIndex,
      answerSessionId: answerSessionId
    }

    this.props.storeAnswerSession(answerSession)

  }

  async changeQuestion(qIndex) {
    const { nextQuestionIndex } = this.state

    if (qIndex <= nextQuestionIndex) {
      this.setState({ questionIndex: qIndex })
    }
  }

  onDateChange = date => this.setState({ date })

  isDayBlocked = (day) => {
    if (this.state.currentGroup.questions[this.state.questionIndex].allowWeekends !== 'undefined' && !this.state.currentGroup.questions[this.state.questionIndex].allowWeekends) {
      if (day.day() === 6 || day.day() === 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  render() {
    const logoUrl = `${confs.host}/api/business/getLogo/${this.props.surveySender}`
    const surveyEndedText = typeof this.props.surveyData.surveyEndedText !== 'undefined' ? this.props.surveyData.surveyEndedText : "Kiitos vastauksistanne! Voitte palata etusivulle allaolevasta napista."

    return (
      <div className="survey-container">
        {this.state.currentGroup &&
          <div>
            <Row className="h-100.mobile survey-inner-container">
              <Col md={{ size: 12 }} className="text-center h-100">
                {this.state.surveyEnded ? (
                  <Row style={{ marginTop: "10em" }}>
                    <Col md={{ size: 12 }}>
                      <Row className="text-center">
                        <Col size='12'>
                          <TextEditor content={surveyEndedText} readOnly={true} onTextChange={() => { return }} textAlignment='center' />
                        </Col>
                        {/* <p style={{width: "100%"}}>Kiitos vastauksistanne! Voitte palata etusivulle allaolevasta napista.</p> */}

                      </Row>
                    </Col>
                  </Row>
                ) : (
                    <div>
                      {this.state.showGroupDescriptionText ? (
                        <div>
                          <Row>
                            <Col xs={{ size: 1 }} md={{ size: 1 }}>
                              <div className="enterprise-logo" style={{ backgroundImage: `url(${logoUrl})` }} />
                            </Col>
                            <Col xs={{ size: 11 }} md={{ size: 11 }} style={{ display: "flex", justifyContent: "center", flex: "0 84%" }}>
                              <h4 style={{ marginTop: "0.5em" }}>{this.state.currentGroup.title}</h4>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={{ size: 12 }}>
                              <TextEditor content={this.state.currentGroup.text} readOnly={true} onTextChange={() => { return }} additionalClass="mt-2" />
                            </Col>
                          </Row>
                          <Row className="answers-container">
                            <Col md={{ size: 12 }}>
                              <button onClick={() => this.setState({ showGroupDescriptionText: false })} className="answer-button survey-answer-button" style={{ margin: 0, borderColor: this.state.groupColor }}>Jatketaan</button>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                          <div>
                            <Row className="question-text" style={{ marginTop: "1em" }}>
                              <Col md={{ size: 12 }}>
                                <h4>{this.state.currentGroup.questions[this.state.questionIndex].text}</h4>
                                {/* <TextEditor content={this.state.currentGroup.questions[this.state.questionIndex].text} readOnly={true} /> */}
                              </Col>
                            </Row>

                            <Row className="survey-answers-container answers-container">
                              <Col md={{ size: 12 }}>
                                {this.state.currentGroup.questions[this.state.questionIndex].isDateQuestion &&
                                  <Row>
                                    <Col>
                                      <DayPickerSingleDateController
                                        date={this.state.date}
                                        isDayBlocked={this.isDayBlocked}
                                        firstDayOfWeek={1}
                                        onDateChange={this.onDateChange}
                                        onDateChange={date => this.setState({ date })}
                                        onFocusChange={({ focused }) => this.setState({ focused })}
                                        focused={this.state.focused}
                                        hideKeyboardShortcutsPanel={true}
                                        class="date_calendar"
                                      />
                                      <button onClick={(e) => this.onAnswer(e)} style={{ padding: "1rem", margin: 0, marginTop: '1rem' }} disabled={this.state.date ? false : true} data-text={this.state.date ? this.state.date._d : null} className="answer-button survey-answer-button">{this.state.date ? 'Vahvista päivämäärä: ' + moment(this.state.date._d).local('fi').format('L') : 'Valitse päivämäärä' }</button>
                                    </Col>
                                  </Row>
                                }
                                {this.state.currentGroup.questions[this.state.questionIndex].isFreetextQuestion &&
                                  <Row>
                                    <Col md={{ size: 12 }}>
                                      {/* <TextEditor content={this.state.rawPlaintextEditorState} readOnly={false} additionalClass="freetext-answer" onTextChange={this.onFreetextAnswerChange} placeholder="Kirjoita vastauksesi tähän" textAlignment='left' /> */}
                                      <textarea className="freetext-answer" placeholder="Kirjoita vastauksesi tähän" value={this.state.freetextAnswer} onChange={(e) => this.setState({ freetextAnswer: e.target.value })}></textarea>
                                      <button onClick={(e) => this.onAnswer(e)} style={{ padding: "1rem", margin: "0", marginTop: '1em' }} data-text={this.state.freetextAnswer} className="answer-button survey-answer-button">Vastaa</button>
                                    </Col>
                                  </Row>
                                }
                                {(!this.state.currentGroup.questions[this.state.questionIndex].isDateQuestion && !this.state.currentGroup.questions[this.state.questionIndex].isFreetextQuestion) &&
                                  <Row>
                                    <Col md={{ size: 12 }}>
                                      <p className="mx-auto"><small>Valitse sopivin vaihtoehto</small></p>
                                      {this.state.currentGroup.questions[this.state.questionIndex].questionOptions.length > 0 && this.state.currentGroup.questions[this.state.questionIndex].questionOptions.map((obj, index) => {
                                        let styles = {}

                                        if (typeof this.state.currentGroup.groupColor !== 'undefined' && this.state.currentGroup.groupColor !== null && typeof this.state.currentGroup.colorInUse !== 'undefined' && this.state.currentGroup.colorInUse) {
                                          // styles.backgroundColor = this.state.currentGroup.groupColor
                                          styles.borderColor = this.state.currentGroup.groupColor
                                        }

                                        /*
                                        TÄSSÄ YKSI VEDOS SIITÄ, ETTÄ NÄYTETÄÄN KÄYTTÄJÄLLE TÄMÄN ANTAMA VASTAUS SIINÄ TAPAUKSESSA ETTÄ NAVIGOIDAAN KYSYMYKSEEN JOHON ON JO ANNETTU VASTAUS
                                        if(typeof this.state.groupAnswers[this.state.questionIndex] !== undefined && parseInt(this.state.groupAnswers[this.state.questionIndex], 10) === parseInt(index, 10)) {
                                          styles.backgroundColor = this.state.currentGroup.groupColor
                                        }
                                        */

                                        return <button onClick={(e) => this.onAnswer(e)} key={index} data-index={index} data-text={obj.text} className="answer-button survey-answer-button" style={styles}>
                                          {obj.text}
                                        </button>
                                      })}
                                    </Col>
                                  </Row>
                                }

                              </Col>
                            </Row>
                          </div>
                        )}


                    </div>
                  )}

              </Col>
            </Row>
            {!this.state.surveyEnded &&
              <div className="survey-nav-row">
                <Col xs={{ size: 12 }} style={{ textAlign: 'center' }}>
                  {this.state.currentGroup.questions.map((q, index) => {
                    let ballColor = index === this.state.questionIndex ? '#ffcc00' : index > this.state.nextQuestionIndex ? '#fff' : 'rgb(181, 228, 19)'
                    let borderColor = ballColor !== '#fff' ? ballColor : '#000000'
                    let style = { backgroundColor: ballColor, borderColor: borderColor }
                    return <div className="survey-nav-ball" style={style} onClick={() => this.changeQuestion(index)}></div>
                  })}
                </Col>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}
