import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import {
  Row,
  Col
} from 'reactstrap'
import TextEditor from '../../modules/TextEditor/texteditor'
import 'react-dates/initialize'
import { DayPickerSingleDateController } from 'react-dates'
import moment from 'moment'
import 'moment/locale/fi';

import 'react-dates/lib/css/_datepicker.css';

import OssiRobotImage from '../../assets/images/ossi_robot.png'
import JaniRobotImage from '../../assets/images/back_jani_robot.png'
import ArioRobotImage from '../../assets/images/arioksanen_robot.jpg'

import { confs } from '../../bootstrap/config'
import './dialog.css'

const axios = require('axios')

export default class Dialog extends Component {
  constructor() {
    super()

    this.state = {
      dialog: null,
      groupIndex: 0,
      questionIndex: 0,
      dialogHistory: [],
      aiWriting: false,
      scrollBarsHeight: '',
      dialogEnded: false,
      currentAnswers: [],
      freetextAnswer: '',
      dialogNotFound: false,
      dialogColor: null,
      date: null,
      showDatePicker: false,
      infoDone: false
    }

    this.dialogContainerRef = React.createRef()
    this.answersContainerRef = React.createRef()
  }

  componentWillMount() {
    if (this.props.dialogData) {
      if (this.props.previousAnswerSession) {
        const prevSession = this.props.previousAnswerSession

        this.setState({
          dialog: this.props.dialogData,
          sentSurveyData: this.props.sentSurveyData,
          groupIndex: prevSession.groupIndex,
          questionIndex: prevSession.questionIndex,
          answerSessionId: prevSession.answerSessionId,
          currentAnswers: [],
          dialogEnded: false
        }, async function () {
          await this.welcomeBack()
          await this.setNextQuestion()
          this.startDialog()
        })
      } else {
        this.setState({
          dialog: this.props.dialogData,
          sentSurveyData: this.props.sentSurveyData,
          dialogColor: this.props.dialogColor,
          answerSessionId: this.props.answerSessionId
        }, () => this.startDialog())
      }
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.calculateScrollbarsHeight)
  }

  componentDidUpdate() {
    if (this.props.dialogData !== this.state.dialog) {
      this.setState({
        dialog: this.props.dialogData,
        groupIndex: 0,
        questionIndex: 0,
        dialogEnded: false
      }, () => this.startDialog())
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calculateScrollbarsHeight)
  }

  async welcomeBack() {
    return new Promise(async function (resolve) {
      this.calculateScrollbarsHeight()
      const dialogObj = {
        text: 'Tervetuloa takaisin. Jatketaanpa siitä mihin jäätiin.',
        type: 'question'
      }
      await this.conversate(dialogObj)
      resolve()
    }.bind(this))
  }

  async startDialog() {
    // await this.loadDialog(code)
    if (this.state.dialog) {
      this.calculateScrollbarsHeight()

      if (!this.props.previousAnswerSession) {
        await this.describeDialog()
        await this.describeGroup()
      }

      if (!this.props.previousAnswerSession || !this.state.dialogEnded) {
        this.askQuestion()
      }
    } else {
      this.setState({ dialogNotFound: true })
    }
  }

  /**
   * Pushes the given conversation object to the dialog history array
   * 
   * params:
   * conversationObj.type String type of either question/feedback or answer (but really anything other than question/feedback is considered as answer)
   * conversationObj.text String yeah it's the text of whatever we're pushing to the conversation
   * conversationObj.isTitle Boolean true if it's the title text of the dialog or the current question group  
   */
  conversate = (conversationObj) => {
    const { text, type } = conversationObj
    const categoryTitle = conversationObj.categoryTitle || false

    return new Promise(function (resolve) {
      if (type === 'question' || type === 'group' || type === 'info' || type === 'comment') {
        const textLength = text.length
        let writeTime;

        if (type === 'group') {
          let numbers = 0;
          text['blocks'].map(item => {
            if (!isNaN(item.text.length)) {
              numbers = numbers + item.text.length;
            }
          })

          // nää ajotukset on hieman nyt väärinpäin. eli tavoitteena olis, että seuraava teksti heti kysymysryhmän jälkeen tulee hitaammin. tässä tapauksessa kysymysryhmän teksti tuli hitaammin.
          // writeTime = numbers * 250 > 7000 ? 7000 : numbers * 250
          // writeTime = (writeTime < 4000 || isNaN(writeTime)) ? 4000 : writeTime
          writeTime = textLength * 10 > 7000 ? 7000 : textLength * 10
          writeTime = (writeTime < 2000 || isNaN(writeTime)) ? 2000 : writeTime
        } else {
          writeTime = textLength * 10 > 7000 ? 7000 : textLength * 10
          writeTime = (writeTime < 2000 || isNaN(writeTime)) ? 2000 : writeTime
        }
        this.setState({
          aiWriting: true
        }, () => {
          setTimeout(() => {
            this.setState(prevState => ({
              dialogHistory: [
                ...prevState.dialogHistory,
                { type: type, text: text, categoryTitle: categoryTitle }
              ],
              aiWriting: false
            }), () => {
              this.handleScrollUpdate()
              resolve()
            })
          }, writeTime)
        })
      } else {
        this.setState(prevState => ({
          dialogHistory: [
            ...prevState.dialogHistory,
            { type: type, text: text }
          ],
          currentAnswers: [],
          freetextAnswer: ''
        }), async function () {
          this.handleScrollUpdate()
          resolve()
        })
      }
    }.bind(this))
  }

  async askQuestion() {
    const { dialog, groupIndex, questionIndex } = this.state
    const currentQuestion = dialog.questionGroups[groupIndex].questions[questionIndex]

    if (currentQuestion.isComment) {
      await this.conversate({ type: 'comment', text: currentQuestion.comment })
    } else {
      await this.conversate({ type: 'question', text: currentQuestion.text })
    }

    if ((typeof currentQuestion.questionOptions === 'undefined' || currentQuestion.questionOptions.length === 0) && dialog.questionGroups) {
      await this.setNextQuestion()
      this.askQuestion()
    } else {
      if (currentQuestion.isFreetextQuestion) {
        this.calculateScrollbarsHeight()
      } else {
        this.setState({
          currentAnswers: currentQuestion.questionOptions
        }, () => this.calculateScrollbarsHeight())
      }
    }
  }

  // when user gives an answer
  async giveAnswer(e) {
    let answerObj = {}
    const { dialog, groupIndex, questionIndex, answerSessionId } = this.state
    const currentDialog = dialog
    const currentGroup = currentDialog.questionGroups[groupIndex]
    const currentQuestion = currentGroup.questions[questionIndex]
    const isFreetextQuestion = currentQuestion.isFreetextQuestion
    const isVariableQuestion = currentQuestion.isVariableQuestion
    const isDateQuestion = currentQuestion.isDateQuestion
    const isComment = currentQuestion.isComment

    const isFinalFeedbackQuestion = e.target.getAttribute("data-is-finalfeedback")



    // finalfeedbackit pakko handlata täysin eri tavalla kuin aiemmat, eli omaan iffiin ja loput rojut elseen

    if (isFinalFeedbackQuestion) {
      this.setState({ askFinalFeedbackQuestion: false, finalFeedbackHasBeenAsked: true, aiWriting: true })
      // tallennetaan annettu sähköposti ja lähetetään siihen finalfeedbacki
      let text = this.state.finalFeedbackAnswer

      answerObj = {
        surveyId: currentDialog._id,
        sentSurveyId: currentDialog.sentSurveyId,
        groupId: '',
        questionId: '',
        answerSessionId: answerSessionId,
        answer: {
          isFinalFeedbackQuestion: true,
          text: text
        },
      }

      await this.props.onSurveyAnswer(answerObj)

      const answer = { type: 'answer', text: text }
      await this.conversate(answer)

    } else if (isComment) {

    } else {
      let text = isFreetextQuestion ? this.state.freetextAnswer : e.target.getAttribute("data-text")

      if (isDateQuestion) {
        text = this.state.date ? this.state.date._d : "Ei valittua päivämäärää"
      }

      const answerIndex = e.target.getAttribute("data-index")

      // store an answer session stack, so it's possible for the user to continue the survey they have left unfinished
      const answerSession = {
        groupIndex: groupIndex,
        questionIndex: questionIndex,
        answerSessionId: answerSessionId
      }

      // maybe do something if storing fails? :))
      await this.props.storeAnswerSession(answerSession)

      if (isFreetextQuestion) {
        answerObj = {
          surveyId: currentDialog._id,
          sentSurveyId: currentDialog.sentSurveyId,
          groupId: currentGroup.id,
          questionId: currentQuestion.id,
          answerSessionId: answerSessionId,
          iframeAnswer: true,
          answer: {
            isFreetextQuestion: true,
            text: text
          },
        }
      } else if (isDateQuestion) {
        answerObj = {
          surveyId: currentDialog._id,
          sentSurveyId: currentDialog.sentSurveyId,
          groupId: currentGroup.id,
          questionId: currentQuestion.id,
          answerSessionId: answerSessionId,
          iframeAnswer: true,
          answer: {
            isDateQuestion: true,
            text: text
          },
        }
      } else {
        answerObj = {
          surveyId: currentDialog._id,
          sentSurveyId: currentDialog.sentSurveyId,
          groupId: currentGroup.id,
          questionId: currentQuestion.id,
          answer: currentQuestion.questionOptions[answerIndex],
          answerSessionId: answerSessionId,
          isVariableQuestion: isVariableQuestion,
          iframeAnswer: true
        }
      }

      // send the answer to server
      const response = await axios({
        method: 'POST',
        url: confs.url + 'surveyAnswer',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
          ...answerObj
        }
      })

      if (!response.data.success) {
        console.error("Something went wrong. Cannot save answer.")
      }

      if (isDateQuestion) {
        this.setState({ showDatePicker: false, date: null });
        if (text !== 'Ei valittua päivämäärää') {
          text = JSON.stringify(moment(text).local('fi').format('L'));
        }
      }

      const answer = { type: 'answer', text: text, answerIndex: answerIndex }
      await this.conversate(answer)

      this.setState({ infoDone: false });

      if (answerIndex) {
        await this.giveFeedback(answerIndex)
        this.handleScrollUpdate()
      }

    }
    const askNextQuestion = await this.setNextQuestion()
    if (askNextQuestion) { this.askQuestion() }
  }

  // give feedback to answer if there is any
  async giveFeedback(answerIndex) {
    const { dialog, groupIndex, questionIndex } = this.state
    const currentQuestion = dialog.questionGroups[groupIndex].questions[questionIndex]
    let givenAnswer = currentQuestion.questionOptions[answerIndex]

    return new Promise(async function (resolve) {
      if (typeof givenAnswer.dialogFeedback !== 'undefined' && typeof givenAnswer.dialogFeedback.blocks !== 'undefined' && (givenAnswer.dialogFeedback.blocks[0].text.trim() !== '' || givenAnswer.dialogFeedback.blocks.length > 1)) {

        givenAnswer = await this.getFeedbackParams(givenAnswer)

        await this.conversate({ type: 'question', text: givenAnswer.dialogFeedback })
        resolve()
      } else {
        resolve()
      }
    }.bind(this))
  }

  getFeedbackParams = (givenAnswer) => {
    const { dialog, questionIndex, groupIndex } = this.state
    const currentQuestion = dialog.questionGroups[groupIndex].questions[questionIndex]

    return new Promise(async function (resolve) {
      if (givenAnswer.dialogPlaintextFeedback) {
        // regex that finds all words that begin with an @ -sign
        const regex = /@\w*/g
        const matches = givenAnswer.dialogPlaintextFeedback.match(regex)

        if (matches && matches.length > 0) {
          const params = {
            sentSurveyId: dialog.sentSurveyId,
            questionId: currentQuestion.id,
            answerId: givenAnswer.id,
            feedbackParams: matches
          }

          const feedbackParams = await this.getFeedbackParamsValues(params)

          if (feedbackParams) {
            matches.map(matchType => {
              // add different types of feedbackparameter types to this switch case, if needed
              switch (matchType) {
                case '@prosentti':
                  givenAnswer.dialogFeedback.blocks.map((block, index) => {
                    if (block.text.indexOf('@prosentti') !== -1) {
                      block.text.replace('@prosentti', feedbackParams.sameAnswersPercentage)
                      block.text = block.text.replace("@prosentti", Math.round(feedbackParams.sameAnswersPercentage) + '%')
                      givenAnswer.dialogFeedback.blocks[index] = block

                      return givenAnswer.dialogFeedback.blocks.splice(index, 1, block)
                    }
                  })
              }
            })

            resolve(givenAnswer)
          }
        }
      }

      resolve(givenAnswer)
    }.bind(this))
  }

  getFeedbackParamsValues = (params) => {
    return new Promise( async function( resolve ) {
      const responseJson = await fetch( confs.url + 'getFeedbackParameterValues', { method: 'POST', body: JSON.stringify( params ) } )
      const response = await responseJson.json()
  
      if(response) {
        resolve( response )
      }
    })
  }

  talkInfo = () => {
    this.conversate({ type: 'info', text: this.state.dialog.questionGroups[this.state.groupIndex].questions[this.state.questionIndex].information });
    this.setState({ infoDone: true });
  }

  // moves to next question if current group has more questions
  // moves to next group if current group has no more questions
  // ends current dialog if it has no more questions
  setNextQuestion = () => {
    const { dialog, groupIndex, questionIndex } = this.state
    const currentGroup = dialog.questionGroups[groupIndex]

    return new Promise(async function (resolve) {
      if (typeof currentGroup.questions !== 'undefined' && currentGroup.questions[questionIndex + 1]) {
        // move to next question in group
        this.setState(prevState => ({
          questionIndex: prevState.questionIndex + 1
        }), () => resolve(true))
      } else {
        if (dialog.questionGroups[groupIndex + 1]) {
          // move to next group in dialog
          await this.setNextGroup()
          resolve(true)
        } else {
          if (this.props.checkNextSurveyType()) {
            // move to next dialog
            this.endDialog(true)
            resolve(false)
          } else {
            // end the dialog
            this.endDialog()
          }
        }
      }
    }.bind(this))
  }

  // starts the next question group of current dialog
  setNextGroup = () => {
    const { dialog } = this.state

    return new Promise(async function (resolve) {
      this.setState(prevState => ({
        questionIndex: 0,
        groupIndex: prevState.groupIndex + 1
      }), async function () {
        if (typeof dialog.questionGroups[this.state.groupIndex].questions !== 'undefined') {
          await this.describeGroup()
          resolve()
        } else {
          this.setNextQuestion()
          resolve()
        }
      })
    }.bind(this))
  }

  // prints out dialog description
  async describeDialog() {
    const { dialog } = this.state

    return new Promise(async function (resolve) {
      // const dialogDescription = dialog.plaintextCategoryExplanation ? dialog.plaintextCategoryExplanation : null
      const dialogDescription = dialog.text ? dialog.text : null
      const trimmedDescription = dialog.plaintextCategoryExplanation.trim()
      
      if(dialogDescription && trimmedDescription !== '') {
        await this.conversate({ type: 'question', text: dialogDescription, categoryTitle: true })
      }
      resolve()
    }.bind(this))
  }

  // prints out question group description
  async describeGroup() {
    const { dialog, groupIndex } = this.state

    return new Promise(async function (resolve) {
      const currentGroup = dialog.questionGroups[groupIndex]
      // const groupDescription = currentGroup.plaintextGroupDescription ? currentGroup.plaintextGroupDescription : null
      const groupDescription = currentGroup.text ? currentGroup.text : null
      const trimmedDescription = currentGroup.plaintextGroupDescription ? currentGroup.plaintextGroupDescription.trim() : ''
      
      if(groupDescription && trimmedDescription !== '') {
        await this.conversate({ type: 'question', text: groupDescription, categoryTitle: true })
      }
      resolve()
    }.bind(this))
  }

  // prints out dialog ending text and ends the dialog
  async endDialog(toNextSurvey = false) {
    const { dialog, sentSurveyData } = this.state
    return new Promise(async function (resolve) {
      if (!toNextSurvey) {
        this.props.clearAnswerSession(sentSurveyData._id)
      }

      // const dialogEndText = typeof dialog.plaintextSurveyEndedText !== 'undefined' ? dialog.plaintextSurveyEndedText : 'Kiitos vastauksistasi!'
      const dialogEndText = typeof dialog.surveyEndedText !== 'undefined' ? dialog.surveyEndedText : 'Kiitos vastauksistasi!'

      await this.conversate({ type: 'question', text: dialogEndText })
      this.setState({ dialogEnded: true, toNextSurvey: toNextSurvey }, () => resolve())
    }.bind(this))
  }

  // calculates the height of the "dialog area" (the part where the conversation history is)
  calculateScrollbarsHeight = () => {
    if (this.state.dialog && this.dialogContainerRef && this.answersContainerRef) {
      const containerHeight = this.dialogContainerRef.current.clientHeight
      const answersHeight = this.answersContainerRef.current.clientHeight
      const minHeight = containerHeight / 3

      // set scrollbars height to containerHeight - answersContainerHeight - another 8% of containerHeight (to make the fit a little bit better)
      let height = containerHeight - answersHeight - (containerHeight * 0.08)
      height = height < minHeight ? minHeight : height

      this.setState({ scrollBarsHeight: height, windowHeight: window.innerHeight }, () => {
        this.handleScrollUpdate()
      })
    }
  }

  handleScrollUpdate = () => {
    this.scrollBars.view.scroll({
      top: this.scrollEnd.offsetTop,
      behavior: 'smooth',
   });
  }

  onDateChange = date => this.setState({ date })

  isDayBlocked = (day) => {
    if (this.state.dialog.questionGroups[this.state.groupIndex].questions[this.state.questionIndex].allowWeekends !== 'undefined' && !this.state.dialog.questionGroups[this.state.groupIndex].questions[this.state.questionIndex].allowWeekends) {
      if (day.day() === 6 || day.day() === 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   * BELOW NOT IN USE AT THE MOMENT
   */
  checkScrollPosition = () => {
    if (this.dialogIsScrolledToBottom()) {
      this.setState({ scrollToBottomVisible: false })
    }
  }

  dialogIsScrolledToBottom = () => {
    if (!this.scrollEnd && !this.scrollBars) return false
    const top = this.scrollEnd.getBoundingClientRect().top
    const scrollBarsHeight = this.scrollBars.getScrollHeight()

    return top <= scrollBarsHeight
  }

  /**
   * ABOVE NOT IN USE AT THE MOMENT
   */

  render() {
    const currentDialog = this.state.dialog || null
    const currentGroup = currentDialog ? currentDialog.questionGroups[this.state.groupIndex] : null
    const currentQuestion = (currentGroup && typeof currentGroup.questions !== 'undefined') ? currentGroup.questions[this.state.questionIndex] : null
    const groupColor = (currentGroup && typeof currentGroup.groupColor !== 'undefined' && (typeof currentGroup.colorInUse !== 'undefined' && currentGroup.colorInUse === true)) ? currentGroup.groupColor : '#FFCC00'
    const { showDatePicker } = this.state;

    return (
      <div className="dialog-container" ref={this.dialogContainerRef}>
        {this.state.dialog !== null &&
          <div>
            <Row style={{ position: 'relative' }}>
              {/* {this.state.scrollToBottomVisible &&
                <div className="scroll-to-bottom-container" title="Uusia viestejä alla" onClick={() => this.checkScrollPosition(true)}><i className="material-icons scroll-to-bottom">arrow_downward</i></div>
              } */}

              <Col sm={{ size: 12 }} style={{ padding: 0 }}>
                <h3 className="dialog-header">{currentDialog.title}</h3>
                <Scrollbars
                  renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                  renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                  style={{ width: "100%", height: this.state.scrollBarsHeight, marginBottom: "1em" }}
                  className="scrollbars-container"
                  ref={(scrollBars) => this.scrollBars = scrollBars}
                >
                  <Col md={{ size: 12 }} className="dialog-inner-container">
                    {this.state.dialogHistory.map((dialog, index) => {
                      let style = {}

                      if (this.state.sentSurveyData.name.indexOf('Ossi Aura') !== -1) {
                        style = { backgroundImage: `url(${OssiRobotImage})`, backgroundSize: 'cover' }
                      } else if (this.state.sentSurveyData.name.indexOf('Jani Bäck') !== -1) {
                        style = { backgroundImage: `url(${JaniRobotImage})`, backgroundSize: 'cover' }
                      } else if (this.state.sentSurveyData.name.indexOf('ariox') !== -1) {
                        style = { backgroundImage: `url(${ArioRobotImage})`, backgroundSize: 'cover' }
                      }

                      if (this.state.dialog.dialogImage) {
                        style = { backgroundImage: `url(${confs.host + '/api/getDialogImage/' + this.state.dialog.dialogImage})`, backgroundSize: 'cover' }
                      }

                      switch (dialog.type) {
                        case 'question':
                        case 'feedback':
                        case 'info':
                        case 'comment':
                          return <Row key={index} className="single-dialog-container">
                            <div className="picture-circle question-picture" style={style} />
                            <div className={"dialog-line dialog-question " + (dialog.categoryTitle || dialog.type === 'info' || dialog.type === 'comment' ? "title-question" : "")}>
                              {/* <p>{dialog.text}</p> */}
                              <TextEditor content={dialog.text} readOnly={true} onTextChange={() => { return }} additionalClass="dialog-freetext-question" />
                              {this.state.dialog.questionGroups[this.state.groupIndex].questions[this.state.questionIndex].information && dialog.type === 'question' && index + 1 === this.state.dialogHistory.length && !this.state.infoDone && !this.state.aiWriting &&
                                <i className="fas fa-info-circle dialogInfoBlock" onClick={this.talkInfo}></i>
                              }
                            </div>
                          </Row>
                        case 'answer':
                          return <Row key={index} className="single-dialog-container">
                            <div className="dialog-line dialog-answer">
                              <p>{dialog.text}</p>
                            </div>
                            <div className="picture-circle answer-picture" />
                          </Row>
                        default:
                          return false;
                      }

                    })}
                  </Col>
                  <div ref={(el) => { this.scrollEnd = el; }}></div>
                </Scrollbars>
              </Col>
            </Row>
            <div className="row dialog-answers-container" ref={this.answersContainerRef}>
              <div className="dialog-answer-inner-container">
                {this.state.aiWriting && <p>Kirjoittaa<span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span></p>}

                {!this.state.dialogEnded ? (
                  currentQuestion && (currentQuestion.isFreetextQuestion || currentQuestion.isDateQuestion) ? (
                    !this.state.aiWriting &&
                    <Col md={{ size: 12 }} style={{ padding: 0 }}>
                      {currentQuestion.isFreetextQuestion ?
                        <span>
                          {/* <TextEditor content={this.state.rawFreetextEditorState} readOnly={false} onTextChange={this.onFreetextAnswerChange} additionalClass="freetext-answer" textAlignment="left" placeholder="Kirjoita vastauksesi tähän" /> */}
                          <textarea className="freetext-answer" placeholder="Kirjoita vastauksesi tähän" value={this.state.freetextAnswer} onChange={(e) => this.setState({ freetextAnswer: e.target.value })}></textarea>
                          <button className="dialog-answer-button" onClick={(e) => this.giveAnswer(e)} style={{ borderColor: this.state.dialogColor }}>Vastaa</button>
                        </span>
                        :
                        <Row style={{ marginLeft: 0, marginRight: 0 }}>
                          <Col md={{ size: 12 }} style={{ display: 'flex' }}>
                            {showDatePicker &&
                              <div style={{ margin: 'auto', marginBottom: '1em' }}>
                                <DayPickerSingleDateController
                                  isDayBlocked={this.isDayBlocked}
                                  firstDayOfWeek={1}
                                  date={this.state.date}
                                  onDateChange={this.onDateChange}
                                  onDateChange={date => this.setState({ date })}
                                  onFocusChange={({ focused }) => this.setState({ focused })}
                                  focused={this.state.focused}
                                  hideKeyboardShortcutsPanel={true}
                                />
                              </div>
                            }
                          </Col>
                            {this.state.date && this.state.date._d ?
                              <button className="dialog-answer-button" style={{ borderColor: this.state.dialogColor }} onClick={(e) => this.giveAnswer(e)}>Vahvista päivämäärä: {moment(this.state.date._d).local('fi').format('L')}</button>
                              :
                              <button className="dialog-answer-button" style={{ borderColor: this.state.dialogColor }} onClick={() => this.setState({ showDatePicker: !showDatePicker })}>Valitse päivämäärä</button>
                            }
                        </Row>
                      }
                    </Col>
                  ) : (
                      currentQuestion.isComment ?
                        !this.state.aiWriting &&
                          <button className="dialog-answer-button" style={{ padding: "1rem", borderColor: groupColor }} onClick={(e) => this.giveAnswer(e)}>Jatka</button>
                        :
                        !this.state.aiWriting && this.state.currentAnswers.length > 0 && this.state.currentAnswers.map((obj, index) => {
                          return <button onClick={(e) => this.giveAnswer(e)} style={{ whiteSpace: 'normal', borderColor: groupColor }} key={index} data-index={index} data-text={obj.text} className="dialog-answer-button">
                            {obj.text}
                          </button>
                        })
                    )
                ) : (
                    <Col sm={{ size: 12 }}>
                      {this.state.toNextSurvey ? (
                        <button className="dialog-answer-button" onClick={() => this.props.getNextSurvey()} style={{ borderColor: groupColor }}>Jatketaan!</button>
                      ) : (
                        this.props.closeCallback && <button className="dialog-answer-button" onClick={() => window.top.closeDialog()} style={{ borderColor: groupColor }}>Sulje</button>
                        )}
                    </Col>
                  )}
              </div>
            </div>
          </div>
        }
        {this.state.dialogNotFound &&
          <Row>
            <Col sm={{ size: 12 }}>
              <h2 style={{ display: 'flex', justifyContent: 'center' }}>Dialogia ei löytynyt :(</h2>
            </Col>
          </Row>
        }
      </div>
    )
  }
}
