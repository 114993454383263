import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'

import './askToContinueModal.css'

const AskToContinueModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} className={props.className} size='lg'>
      <ModalHeader toggle={props.toggle}>Qartoituksen jatkaminen</ModalHeader>
      <ModalBody>
        Havaitsin selaimellasi kesken jääneen Qartoituksen. Haluaisitko jatkaa siitä mihin jäit?
      </ModalBody>
      <ModalFooter>
        <button className="modal-button modal-accept-button" onClick={() => props.continue()}>Jatka</button>{' '}
        <button className="modal-button modal-new-button" onClick={() => props.startNew()}>Aloita uusi</button>
      </ModalFooter>
    </Modal>
  )
}

export default AskToContinueModal