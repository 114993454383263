import React, { Component } from 'react'
import {
  Container
} from 'reactstrap'

import { confs } from '../../bootstrap/config'
import Dialog from '../Dialog/dialog';
import Survey from '../Survey/survey';
import AskToContinueModal from '../../modules/AskToContinueModal/askToContinueModal'

const axios = require('axios')
const queryString = require('query-string')

export default class SurveyLoader extends Component {
  constructor() {
    super()

    this.state = {
      surveyData: null,
      currentSurvey: null,
      surveyType: null,
      surveyIndex: 0,
      colorHEX: null
    }

    this.surveyData = null
    this.previousAnswerSession = null

    this.closeCallback = false
  }

  componentWillMount() {
    if (typeof this.props.match.params.code !== 'undefined' && this.props.match.params.code !== null) {
      let code = this.props.match.params.code || ""

      if (code !== "") {
        code = code.toUpperCase()

        this.loadSurvey(code)
      }
    }

    if (typeof this.props.location.search !== 'undefined' && this.props.location.search !== null) {
      const urlParams = queryString.parse(this.props.location.search)
      let colorHEX = '#FFCC00'

      if (typeof urlParams.colorhex !== 'undefined') {
        if (urlParams.colorhex.indexOf('rgb') === -1) {
          colorHEX = `#${urlParams.colorhex}`
        } else {
          colorHEX = `${urlParams.colorhex}`
        }
      }

      if(typeof urlParams.closeCallback !== 'undefined') {
        console.log("tulee")
        this.closeCallback = true
      }

      this.setState({ colorHEX: colorHEX })
    }
  }

  checkPreviousSession = (sentSurveyId) => {
    const previousSessionJson = localStorage.getItem(sentSurveyId)

    if (previousSessionJson !== null) {
      return (JSON.parse(previousSessionJson))
    } else {
      return false
    }
  }

  async loadSurvey(code) {
    try {
      //get the dialog here
      const response = await axios({
        method: 'POST',
        url: confs.url + 'getSurvey',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
          code: code
        }
      })

      if (response.data.success) {
        try {
          const sentSurvey = response.data.surveys
          const firstSurvey = sentSurvey.surveys[0]
          const surveyType = firstSurvey.type
          const answerSessionId = response.data.sessionId

          // temporarily store surveyData locally. when put to state, starts dialog/survey. first need to check
          // if there is a previous session to continue.
          this.surveyData = sentSurvey

          this.setState({
            currentSurvey: firstSurvey,
            surveyType: surveyType,
            answerSessionId: answerSessionId
          }, () => {
            //check localstorage for previous session with this sentsurvey
            const prevSession = this.checkPreviousSession(sentSurvey._id)

            if (prevSession) {
              this.previousAnswerSession = prevSession
              this.setState({ askToContinue: true })
            } else {
              this.startNewAnswerSession()
            }
          })

        } catch (error) {
          console.error("Cannot load survey: " + error)
        }

      }

    } catch (error) {
      console.error(error)
    }
  }

  checkNextSurveyType = () => {
    const { surveyData } = this.state

    if (surveyData.surveys[this.state.surveyIndex + 1]) {
      return surveyData.surveys[this.state.surveyIndex + 1].type
    }
    return false
  }

  getNextSurvey = () => {
    this.setState(prevState => ({
      surveyIndex: prevState.surveyIndex + 1
    }), () => {
      const nextSurvey = this.state.surveyData.surveys[this.state.surveyIndex]
      const surveyType = nextSurvey.type

      this.setState({
        currentSurvey: nextSurvey,
        surveyType: surveyType
      })
    })
  }

  isNextSurvey = () => {
    if(this.state.surveyData.surveys[this.state.surveyIndex + 1]) {
      this.getNextSurvey();
    } else {
      return false
    }
  }

  storeAnswerSession = (answerSession) => {
    const { surveyIndex, surveyData } = this.state

    return new Promise(function (resolve) {
      try {
        answerSession.surveyIndex = surveyIndex
        localStorage.setItem(surveyData._id, JSON.stringify(answerSession))
        resolve(true)
      } catch (error) {
        console.error(error)
        resolve(false)
      }
    }.bind(this))
  }

  clearAnswerSession = (sentSurveyId) => {
    localStorage.removeItem(sentSurveyId)
  }

  startNewAnswerSession = () => {
    this.clearAnswerSession(this.surveyData._id)
    this.setState({
      surveyData: this.surveyData,
      askToContinue: false
    })
  }

  continuePreviousSession = () => {
    this.setState({
      surveyData: this.surveyData,
      currentSurvey: this.surveyData.surveys[this.previousAnswerSession.surveyIndex],
      previousAnswerSession: this.previousAnswerSession,
      surveyIndex: this.previousAnswerSession.surveyIndex,
      askToContinue: false
    })
  }

  toggleContinueModal = () => {
    this.setState(prevState => ({
      askToContinue: !prevState.askToContinue
    }))
  }

  render() {
    const { surveyData, surveyType, currentSurvey, answerSessionId, askToContinue } = this.state

    return (
      <Container fluid style={{ height: "100%" }}>
        <AskToContinueModal isOpen={askToContinue} toggle={this.toggleContinueModal} continue={this.continuePreviousSession} startNew={this.startNewAnswerSession} />
        {surveyData && surveyType && surveyType === 'dialog' &&
          <Dialog
            dialogData={currentSurvey}
            sentSurveyData={surveyData}
            checkNextSurveyType={this.checkNextSurveyType}
            getNextSurvey={this.getNextSurvey}
            dialogColor={this.state.colorHEX}
            answerSessionId={answerSessionId}
            storeAnswerSession={this.storeAnswerSession}
            previousAnswerSession={this.state.previousAnswerSession}
            clearAnswerSession={this.clearAnswerSession}
            closeCallback={this.closeCallback}
          />
        }
        {surveyData && surveyType && surveyType === 'Kartoitus' &&
          <Survey
            surveyData={this.state.currentSurvey}
            sentSurveyData={this.state.surveyData}
            checkNextSurveyType={this.checkNextSurveyType}
            getNextSurvey={this.isNextSurvey}
            surveyColor={this.state.colorHEX}
            storeAnswerSession={this.storeAnswerSession}
            previousAnswerSession={this.state.previousAnswerSession}
            answerSessionId={answerSessionId}
            clearAnswerSession={this.clearAnswerSession}
            />
        }
      </Container>
    )
  }
}
