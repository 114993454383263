import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import Home from './Components/Home/home'
import SurveyLoader from './Components/SurveyLoader/surveyLoader'
import './App.css'

class App extends Component {
  render() {
    return (
      <div className="App">
        <header>

        </header>
        <main>
          <Switch>
            <Route exact path="/:code" component={SurveyLoader} />
            <Route component={Home} />
          </Switch>
        </main>
      </div>
    )
  }
}

export default App
