import React from 'react'
import { 
  EditorState, 
  ContentState, 
  convertFromRaw,
  convertToRaw
 } from 'draft-js'
import Editor from 'draft-js-plugins-editor'

/** Draft-js plugins */
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin'
import 'draft-js-inline-toolbar-plugin/lib/plugin.css' 

import createLinkPlugin from 'draft-js-anchor-plugin'
import 'draft-js-anchor-plugin/lib/plugin.css'

import createVideoPlugin from 'draft-js-video-plugin'
import createImagePlugin from 'draft-js-image-plugin'

import './texteditor.css'

const inlineToolbarPlugin = createInlineToolbarPlugin()
const { InlineToolbar } = inlineToolbarPlugin

const linkPlugin = createLinkPlugin({
  placeholder: 'Linkin osoite',
  linkTarget: '_blank'
})

const videoPlugin = createVideoPlugin()
const imagePlugin = createImagePlugin()

const plugins = [
  inlineToolbarPlugin, linkPlugin, videoPlugin, imagePlugin
]

class TextEditor extends React.Component {
  constructor() {
    super()

    this.state = {
      editorState: EditorState.createEmpty()
    }
  }

  componentWillMount() {
    const textContent = this.props.content
    let contentState = ''
    if(typeof textContent === 'string') {
      contentState = ContentState.createFromText(textContent)
    } else {
      contentState = convertFromRaw(textContent)
    }
    this.setState({
      editorState: EditorState.createWithContent(contentState)
    })
  }

  componentDidUpdate(prevProps) {    
    if(prevProps.editorId !== this.props.editorId) {
      const textContent = this.props.content
      let contentState = ''
      
      if(typeof textContent === 'string') {
        contentState = ContentState.createFromText(textContent)
      } else {
        contentState = convertFromRaw(textContent)
      }

      this.setState({
        editorState: EditorState.createWithContent(contentState), 
        editorReadOnly: this.props.readOnly || false,
        additionalClass: this.props.additionalClass || ""
      })
    }
  }

  onChange = (editorState) => {
    if(this.props.content === '') {
      editorState = EditorState.createEmpty()
    }
    this.setState({editorState})
    this.props.onTextChange(convertToRaw(editorState.getCurrentContent()))
  }

  onURLChange = (e) => {
    this.setState({urlValue: e.target.value})
  }

  focus = () => {
    this.editor.focus()
  }

  render() {
    const { additionalClass, readOnly, placeholder, textAlignment } = this.props

    return(
      <div onClick={this.focus} className={"texteditor-container " + (readOnly ? "readonly " : "") + (additionalClass ? additionalClass : "")}>
        <Editor 
          editorState={this.state.editorState} 
          onChange={this.onChange} 
          handleKeyCommand={this.handleKeyCommand}
          readOnly={readOnly}
          ref={(element) => {this.editor = element}}
          plugins={plugins}
          placeholder={placeholder}
          textAlignment={textAlignment}
        />
        {/* {!this.state.readOnly && 
          <InlineToolbar>
            {
              // may be use React.Fragment instead of div to improve perfomance after React 16
              (externalProps) => (
                <React.Fragment>
                  <HeadlineThreeButton {...externalProps} />
                  <BoldButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  <linkPlugin.LinkButton {...externalProps} />
                </React.Fragment>
              )
            }
          </InlineToolbar>
        } */}
      </div>
    )
  }

}

export default TextEditor